import { PaletteColor } from '@mui/material';
import axios from 'axios';
import { InsuredType } from './checkout';
import { CheckoutProviderType } from './invoice';
import { nonRedirectingAxios } from './util';

export type UIConfig = {
    version: string;
    clientId: string;
    userPoolId: string;
    logoUrl?: string;
    origins?: Origins;
    colors?: {
        primary?: PaletteColor;
        secondary?: PaletteColor;
    };
    displayName?: string;
    favIconUrl?: string;
};

export enum AppPortalType {
    CLIENT = 'CLIENT',
    CHECKOUT = 'CHECKOUT',
    CHECKOUT_HOST = 'CHECKOUT_HOST',
}

export type Origins = { [key in AppPortalType]?: string };

export const getSellerUIConfig = async (): Promise<UIConfig> => {
    return await axios.get(`${process.env.REACT_APP_CHECKOUT_HOST}/seller/_config/ui`).then(({ data }) => data);
};

type ConfigItem = 'enabled' | 'disabled' | 'required' | undefined;
export type OriginationConfig = {
    insuredTypes?: InsuredType[];
    emailAddress: 'required';
    number: ConfigItem;
    name: ConfigItem;
    address: ConfigItem;
};

export const getClientOriginationConfig = async (): Promise<OriginationConfig> => {
    return await nonRedirectingAxios()
        .get(
            `${process.env.REACT_APP_CHECKOUT_HOST}/seller/_config/origination/${CheckoutProviderType.CLIENT_INITIATED}`
        )
        .then(({ data }) => data);
};
