import axios from 'axios';
import { InvoicePaymentMethod } from '../store/reducer/SelectedPaymentMethodReducer';
import { InvoiceSummary } from '../type/Types';

export type Term = {
    uuid: string;
    feeAmount: number;
    totalAmount: number;
    feeCharges: Charge[];
    firstPaymentDate: Date;
    nextPaymentDate: Date;
    interestAmount: number;
    flatInterestRate: number;
    instalmentAmount: number;
    paymentFrequency: PaymentFrequency;
    numberOfPayments: number;
    annualInterestRate: number;
    initialPaymentAmount: number;
    termPaymentMethod: TermPaymentMethod;
    customCheckoutType: CustomCheckoutType;
};

export enum ChargeType {
    TRANSACTION_FEE = 'TRANSACTION_FEE',
    DEFAULT_FEE = 'DEFAULT_FEE',
    SETUP_FEE = 'SETUP_FEE',
}

export enum CalculatedOn {
    PERCENT = 'PERCENT',
    FIXED = 'FIXED',
}

export type Charge = {
    type: ChargeType;
    amount: number;
    initialAmount: number;
    chargeIdentifier: string;
    calculatedOn: CalculatedOn;
    chargeValue: number;
    label?: string;
};

export type TermPaymentMethod = {
    paymentMethodType: InvoicePaymentMethod;
    paymentMethodCharges: Charge[];
    cardPaymentProcessor?: CardPaymentProcessor;
    directDebitAuthorityNumber?: string;
};

export enum CardPaymentProcessor {
    CYBERSOURCE = 'CYBERSOURCE',
    WINDCAVE = 'WINDCAVE',
}

export type TermPaymentConfig = {
    firstInstalmentAmount: number;
    instalmentAmount: number;
    paymentFrequency: PaymentFrequency;
    termCharges: Charge[];
    paymentMethods: TermPaymentMethod[];
    latestFirstPaymentDate: string;
    lending: boolean;
    numberOfPayments: number;
    finalPaymentDate: string;
    interestAmount: number;
    interestRate: number;
};

export type SelectTermResponse = {
    term: Term;
    type: CheckoutType;
};

export type CustomCheckoutSelectTermResponse = SelectTermResponse & {
    providerUrl: string;
};

export enum PaymentFrequency {
    IN_FULL = 'IN_FULL',
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
}

export enum CustomCheckoutType {
    NONE = 'NONE',
    IQUMULATE = 'IQUMULATE',
}

export enum CheckoutType {
    SIMFUNI = 'SIMFUNI',
    CUSTOM = 'CUSTOM',
}

export const fetchTerms = async (invoice: InvoiceSummary): Promise<Term[]> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoice.uuid}/repayment-terms`)
        .then(({ data }) => data);
};

export const setTerm = async (invoiceUuid: string, selectedTerm: string): Promise<SelectTermResponse> => {
    return await axios
        .put(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceUuid}/select-term?selectedTerm=${selectedTerm}`)
        .then(({ data }) => data)
        .catch((error) => {
            const errorMessage = error.response?.data?.message || '';
            if (errorMessage.includes(externalPaymentOptionNotAvaliable)) {
                return Promise.reject(new Error(externalPaymentOptionNotAvaliable));
            }

            return Promise.reject(new Error(general_api_error_message)); // use general error message unless it is external payment option not available error
        });
};

const general_api_error_message = 'Something went wrong, please try again.';
export const externalPaymentOptionNotAvaliable = 'external payment option is not available';
