import { DefaultTexts } from '@aws-amplify/ui';
import { Alert } from '@mui/material';
import { Amplify, I18n } from 'aws-amplify';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutWrapper from './components/LayoutWrapper';
import ManageIntegratedView from './components/ManageIntegratedView';
import useScript from './hooks/useScript';
import BankAccountCorrection from './pages/BankAccountCorrection';
import CreateAccountRouteElement from './pages/CreateAccount/RouteElement';
import GetStartedRouteElement from './pages/GetStarted/RouteElement';
import InvoiceRouteElement from './pages/Invoice/RouteElement';
import InvoicePaid from './pages/InvoicePaid';
import LoadInvoiceFromReferenceRouteElement from './pages/LoadInvoiceFromReference/RouteElement';
import LoanContractRouteElement from './pages/LoanContract/RouteElement';
import NotFound from './pages/NotFound';
import PaymentRouteElement from './pages/Payment/RouteElement';
import PaymentCompleteRouteElement from './pages/PaymentComplete/RouteElement';
import PaymentCorrection from './pages/PaymentCorrection';
import PaymentOptionsRouteElement from './pages/PaymentOptions/RouteElement';
import PaymentProcessingRouteElement from './pages/PaymentProcessing/RouteElement';
import RepaymentSummaryRouteElement from './pages/PaymentSummary/RouteElement';
import ReferenceDetailsRouteElement from './pages/ReferenceDetails/RouteElement';
import UnsolicitedPaymentDetails from './pages/UnsolicitedPayment/PaymentDetails';
import UnsolicitedPersonalDetails from './pages/UnsolicitedPayment/PersonalDetails';
import { useAppSelector } from './store/reducer/Hooks';
import { RootState } from './store/Store';

I18n.putVocabulariesForLanguage('en', {
    [DefaultTexts.CREATE_ACCOUNT]: 'Sign up',
    Username: 'Email',
    [DefaultTexts.ENTER_USERNAME]: 'Email',
    'Enter your Username': 'Email',
    [DefaultTexts.ENTER_PASSWORD]: 'Password',
    [DefaultTexts.WE_TEXTED]: 'We Emailed You',
});

const inIframe = (): boolean => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

function App() {
    const uiConfig = useAppSelector((state: RootState) => state.persistedSellerReducer).uiConfig;
    useScript(`https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`);

    useEffect(() => {
        if (uiConfig != null) {
            Amplify.configure({
                aws_cognito_region: 'ap-southeast-2', // (required) - Region where Amazon Cognito project was created
                aws_user_pools_id: uiConfig.userPoolId, // (optional) -  Amazon Cognito User Pool ID
                aws_user_pools_web_client_id: uiConfig.clientId,
            });
        }
    }, [uiConfig]);

    if (uiConfig == null) {
        return (
            <Alert sx={{ mt: 1 }} severity='error'>
                The payment portal is not setup correctly, please contact Simfuni.
            </Alert>
        );
    }

    return (
        <>
            {inIframe() && <ManageIntegratedView />}
            <Routes>
                <Route path='/pay' element={<ReferenceDetailsRouteElement />} />
                <Route path='/pay/:reference' element={<LoadInvoiceFromReferenceRouteElement />} />
                <Route path='/:uuid?' element={<GetStartedRouteElement />} />
                <Route path='/:uuid/contract' element={<LoanContractRouteElement />} />
                <Route path='/invoice' element={<InvoiceRouteElement />} />
                <Route path='/payment-options' element={<PaymentOptionsRouteElement />} />
                <Route path='/create-account' element={<CreateAccountRouteElement />} />
                <Route path='/payment' element={<PaymentRouteElement />} />
                <Route path='/payment-processing' element={<PaymentProcessingRouteElement />} />
                <Route path='/payment-complete' element={<PaymentCompleteRouteElement />} />
                <Route path='/summary' element={<RepaymentSummaryRouteElement />} />

                {/* The following routing is for bank account correction only */}
                <Route
                    path='/:uuid/correct-bank-account/:token?'
                    element={
                        <LayoutWrapper>
                            <BankAccountCorrection />
                        </LayoutWrapper>
                    }
                />
                <Route
                    path='/payment-correction'
                    element={
                        <LayoutWrapper>
                            <PaymentCorrection />
                        </LayoutWrapper>
                    }
                />
                <Route
                    path='/invoice-paid'
                    element={
                        <LayoutWrapper>
                            <InvoicePaid />
                        </LayoutWrapper>
                    }
                />
                {/* Unsolicited payments */}
                <Route
                    path='/client-payment/payment-details'
                    element={
                        <LayoutWrapper hideHeader>
                            <UnsolicitedPaymentDetails />
                        </LayoutWrapper>
                    }
                />
                <Route
                    path='/client-payment/personal-details'
                    element={
                        <LayoutWrapper hideHeader>
                            <UnsolicitedPersonalDetails />
                        </LayoutWrapper>
                    }
                />

                <Route
                    path='/not-found'
                    element={
                        <LayoutWrapper>
                            <NotFound />
                        </LayoutWrapper>
                    }
                />
            </Routes>
        </>
    );
}

export default App;
